import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { getToken } from "utils/getToken";
import "../../assets/css/scrollBar.css";
import ChatBubble from "./ChatBubble";
import woman from "../../assets/img/avatars/woman.png";

function QNAs() {
  const [chatList, setChatList] = useState([]);
  const [convo, setConvo] = useState([]);
  const [listLoading, setlistLoading] = useState(false);
  const [convoLoading, setConvoLoading] = useState(false);
  const [activeChat, setActiveChat] = useState();
  const [page, setPage] = useState(1);
  const ref = useRef(null);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${getToken()}`,
  };

  const getChatList = async () => {
    setlistLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `bot/conversation/chat-list/`,
        { page: page, sort: "asc" },
        {
          headers,
        }
      );
      if (response.status === 200) {
        setlistLoading(false);
        setChatList(response?.data?.results);
      }
    } catch (error) {
      setlistLoading(false);
      console.log(error);
    } finally {
      setlistLoading(false);
    }
  };

  useEffect(() => {
    setConvo([])
    getChatList();
  }, [page]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [convo]);

  const getConvo = async (id) => {
    setConvoLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `bot/conversation/messages/`,
        { user_id: id, sort: "asc", page: 1 },
        {
          headers,
        }
      );
      if (response.status === 200) {
        setConvoLoading(false);
        console.log(response.data);
        setConvo(response?.data?.results);
        setActiveChat(id);
      }
    } catch (error) {
      setConvoLoading(false);
      console.log(error);
    } finally {
      setConvoLoading(false);
    }
  };

  const getNext = () => {
    setPage(page + 1);
  };
  const getPrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const pagesArray = [];
  for (let i = 1; i <= page; i++) {
    pagesArray.push(i);
  }

  return listLoading ? (
    <div className="flex h-[100vh] items-center justify-center">
      <ClipLoader color={"blue"} size={60} />
    </div>
  ) : (
    <div>
      <h1 className="my-4 text-center text-3xl font-bold">
        Questions and Answers
      </h1>
      <div className="grid grid-cols-6">
        <div className="custom-scrollbar col-span-2 h-[75vh] overflow-auto bg-white">
          {chatList.length > 0 &&
            chatList.map((msg, i) => (
              <div
                onClick={() => getConvo(msg.user_id)}
                key={i}
                className={`cursor-pointer items-center gap-x-4 border-b px-6 py-3 ${
                  activeChat === msg?.user_id ? "bg-gray-300" : ""
                }`}
              >
                <div className="flex items-center gap-2 font-semibold text-gray-800">
                  <img src={woman} className="h-12 w-12" />
                  <div>
                    {msg?.user_details[0]?.first_name}
                    <p className="font-normal text-gray-700">
                      {msg?.last_message.length > 25
                        ? msg?.last_message.slice(0, 24) + "..."
                        : msg?.last_message}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {convoLoading ? (
          <div className="col-span-4 flex h-[75vh] items-center justify-center bg-[#dad3cc]">
            <ClipLoader color={"blue"} size={60} />
          </div>
        ) : (
          <div
            ref={ref}
            className="custom-scrollbar col-span-4 h-[75vh] overflow-auto bg-[#dad3cc] p-4"
          >
            {convo?.map((item, i) => (
              <ChatBubble msg={item} key={i} />
            ))}
          </div>
        )}
      </div>

      <div className="my-4 flex items-center justify-center gap-2 text-white">
        <button className="rounded-lg bg-blue-500 px-4 py-2" onClick={getPrev}>
          Previous
        </button>
        {pagesArray?.map((num, i) => (
          <button
            onClick={() => setPage(num)}
            key={i}
            className={`rounded-lg px-4 py-2 ${
              page === num ? "bg-gray-500" : "bg-blue-500"
            }`}
            disabled={page === num}
          >
            {num}
          </button>
        ))}
        <button className="rounded-lg bg-blue-500 px-4 py-2" onClick={getNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default QNAs;
