import "../../components/WhatsappChat/WhatsappChat.css";
import doctor from "../../assets/img/avatars/doctor.png";
import bot from "../../assets/img/avatars/bot.png";
import woman from "../../assets/img/avatars/woman.png";
function ChatBubble({ msg }) {
  const timeOptions = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  const dateOptions = {
    timeZone: "Asia/Kolkata",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return (
    <div>
      {(msg?.msg?.type === "text" && msg?.sender_type === "bot") ||
      msg?.sender_type === "doctor" ? (
        <div className="flex justify-end gap-x-1">
          <div className={`message-main-sender my-4 flex flex-col rounded-t-md rounded-bl-md bg-blue-500 ${msg?.sender_type === "doctor" ? "bg-teal-100 text-black" : "bg-blue-500 text-white"} p-4`}>
            {msg?.msg?.content}
            <div className="flex items-center justify-end gap-x-1">
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleDateString("en-gb", dateOptions)}
              </p>
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleTimeString("en-gb", timeOptions)}
              </p>
            </div>
          </div>
          {msg?.sender_type === "doctor" && (
            <img src={doctor} className="mb-4 h-6 w-6 self-end" />
          )}
          {msg?.sender_type === "bot" && (
            <img src={bot} className="mb-4 h-6 w-6 self-end" />
          )}
        </div>
      ) : (msg?.msg?.type === "list" && msg?.sender_type === "bot") ||
        msg?.sender_type === "doctor" ? (
        <div className="flex justify-end gap-x-1">
          <div className={`message-main-sender my-4 flex-col rounded-t-md rounded-bl-md bg-blue-500 ${msg?.sender_type === "doctor" ? "bg-teal-100 text-black" : "bg-blue-500 text-white"}`}>
            <h3 className="text-center underline">{msg?.msg?.heading}</h3>
            <ul>
              {msg?.msg?.items?.map((item, i) => (
                <li className="list-disc" key={i}>
                  {item}
                </li>
              ))}
            </ul>
            <div className="flex items-center justify-end gap-x-1">
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleDateString("en-gb", dateOptions)}
              </p>
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleTimeString("en-gb", timeOptions)}
              </p>
            </div>
          </div>
          <img src={bot} className="mb-4 h-6 w-6 self-end" />
        </div>
      ) : msg?.sender_type === "customer" ? (
        <div className="flex justify-start gap-x-1">
          <img src={woman} className="mb-4 mt-4 h-6 w-6 self-start" />
          <div className="message-main-receiver my-4 w-max rounded-b-md rounded-tr-md bg-gray-100 p-4">
            {msg?.msg?.content}
            <div className="flex items-center justify-start gap-x-1">
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleDateString("en-gb", dateOptions)}
              </p>
              <p className="text-[0.7rem]">
                {new Date(msg?.date).toLocaleTimeString("en-gb", timeOptions)}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ChatBubble;
